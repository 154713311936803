import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { FirebaseNotificationContext } from "src/contexts/firebase/firebaseContext";
import { RequestContext } from "src/contexts/requestContext";
import OrchestratorService from "src/services/OrchestratorService";

import { PayloadReceivedProps } from "../types";

export function FirebaseNotificationPayload({
  items,
  userId,
  onClose,
  setShow,
}: PayloadReceivedProps) {
  const orchestratorService = OrchestratorService.Instance;
  const firebaseContext = useContext(FirebaseNotificationContext);
  const requestContext = useContext(RequestContext);
  const isIframe = window.top !== window.self;

  const isAuthorize = useMemo(() => {
    return items === "sim";
  }, [items]);

  useEffect(() => {
    if (!requestContext?.isFetch) {
      firebaseContext?.setIsNotificationAuthorized(isAuthorize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorize]);

  const fetchNotifyWebhook = useCallback(
    async (messingToken: string) => {
      try {
        requestContext?.setIsFetch(true);

        const data = JSON.stringify({
          type: "push_notification",
          payload: messingToken,
        });

        // const message = "##NOSHOW##" + data;
        localStorage.setItem("userId", userId ?? "");

        if (!!onClose) {
          await orchestratorService.sendNotifyWebhook({
            id: userId,
            event: onClose,
            eventParameters: {
              msg: "payload",
              payload: data,
            },
          });
        }

        requestContext?.setIsFetch(false);
        setShow(false);
      } catch (err) {
        requestContext?.setIsFetch(false);
        setShow(false);
      }
    },
    [onClose, orchestratorService, requestContext, setShow, userId]
  );

  const actionsIfIsIframe = useCallback(() => {
    window.fetchNotifyWebookWithFirebaseToken = (token) => {
      if (firebaseContext) firebaseContext.messingToken = token;

      fetchNotifyWebhook(token);
    };
    window.parent.postMessage("requestNotification", "*");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIframe) actionsIfIsIframe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isIframe) {
      if (!requestContext?.isFetch) {
        if (
          isAuthorize &&
          firebaseContext?.isNotificationsAllowed &&
          firebaseContext?.messingToken !== null
        ) {
          fetchNotifyWebhook(firebaseContext?.messingToken ?? "");
        }

        if (isAuthorize && !firebaseContext?.supported) {
          fetchNotifyWebhook("");
          firebaseContext?.resetNotification();
        }

        if (!isAuthorize) {
          fetchNotifyWebhook("");
          firebaseContext?.resetNotification();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseContext]);

  return <></>;
}
