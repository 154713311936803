import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { CONTENTTYPE, MESSAGETYPE } from "src/contexts/store/appReducer";
import { IoIosCopy } from "react-icons/io";

interface MessageItemProps {
  msg: any;
  listOrCarousel: boolean;
  index: number;
  isDateNow: boolean;
  groupMessages: any[]; //
  nowDateToMessage: string; //
  renderMessage: (props: any) => JSX.Element;
}

export function MessageItem({
  msg,
  listOrCarousel,
  index,
  isDateNow,
  groupMessages,
  nowDateToMessage,
  renderMessage,
}: MessageItemProps) {
  const doc = document.documentElement;
  const messageBoxShadow = doc.style.getPropertyValue("--message__box-shadow");
  const timeout = useRef<NodeJS.Timeout>();

  const startSelectCount = useCallback(() => {
    timeout.current = setTimeout(() => {
      navigator.clipboard.writeText(msg.text);
      toast.info("Mensagem copiada para a área de transferência", {
        autoClose: 2000,
      });
    }, 1750);
  }, [msg.text]);

  const isLastMessage =
    isDateNow && index === groupMessages[nowDateToMessage]?.length - 1;
  const isSecondLastMessage =
    isDateNow && index === groupMessages[nowDateToMessage]?.length - 2;

  function handleCopy() {
    if (window.isIframe()) {
      const copyData = { copyData: msg.text };
      window.parent.postMessage(copyData, "*");

      return;
    }

    window.copyToClipboard(msg.text);
  }

  return (
    <div>
      <div
        className={
          msg.type === MESSAGETYPE.INCOMING
            ? `message left ${listOrCarousel ? "message-carrossel" : ""}`
            : `message right ${
                msg.contentType === CONTENTTYPE.AUDIO && "audio"
              }`
        }
      >
        <div
          onTouchStart={() => startSelectCount()}
          onTouchEnd={() => clearTimeout(timeout.current)}
          style={{
            boxShadow: listOrCarousel ? "none" : messageBoxShadow,
          }}
          className={`
        message-balloon
        ${!listOrCarousel && "message-balloon-bg"}
        ${msg.contentType === CONTENTTYPE.AUDIO && "audio"}
      `}
        >
          {renderMessage({
            msg: msg,
            isLastMessage,
            isSecondLastMessage,
          })}
        </div>
        {msg.contentType === "text" && msg.type === "incoming" && (
          <button title="Copiar" onClick={handleCopy} className="copy-button">
            <IoIosCopy className="copy-icon" />
          </button>
        )}
      </div>
    </div>
  );
}
