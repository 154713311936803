import { ACTIONS } from "./appReducer";
import { v4 as uuidv4 } from "uuid";

export const AuthStorageKeys = {
  TOKEN: "token",
  BID: "bid",
  PID: "pid",
  DISPLAY_MESSAGE: "displayMessage",
  MSG_HISTORY: "msg_history",
};

export class Authentication {
  instance = null;

  isAuthorized = false;
  api;

  constructor(api) {
    this.api = api;
  }

  async authorize(dispatch, renew = false) {
    return new Promise((res, _rej) => {
      handleRenew(renew);
      createBidIfNotExist();

      const token = getLocalToken();

      let pid = localStorage.getItem(AuthStorageKeys.PID) || null;

      if (!token) this.requestAuthenticationWithToken(dispatch, pid, res);
      else res(token);
    });
  }

  async requestAuthenticationWithToken(dispatch, pid, res) {
    try {
      const r = await this.api.get("/authorization", {
        headers: {
          "X-Api-Key": `${process.env.REACT_APP_BOT}-${process.env.REACT_APP_STAGE}`,
          Authorization: localStorage.getItem(AuthStorageKeys.BID),
          Partner: pid,
        },
      });

      localStorage.setItem("token", r.data.token);
      dispatch({ type: ACTIONS.AUTHORIZED });
      res(r.data.token);
    } catch (e) {
      // logger.error("Autenticação Falho", e);
      if (e?.response?.status === 401)
        dispatch({ type: ACTIONS.NOT_AUTHORIZED });
    }
  }
}

const getLocalToken = () => {
  return localStorage.getItem("token") &&
    !isTokenExpired(localStorage.getItem("token"))
    ? localStorage.getItem("token")
    : null;
};

const handleRenew = (renew = false) => {
  if (renew) {
    Object.values(AuthStorageKeys).forEach((k) => {
      localStorage.removeItem(k);
    });
  }
};

const createBidIfNotExist = () => {
  if (!localStorage.getItem("bid")) localStorage.setItem("bid", uuidv4());
};

function isTokenExpired(token) {
  try {
    var exp = JSON.parse(atob(token.split(".")[1])).exp;
    var date = new Date(exp * 1000);
    return date <= new Date();
  } catch (err) {
    return true;
  }
}
