import React from "react";
import "./List.scss";

export default function List(props) {
  const { msg, setItemSelected, itemSelected, onMessage, lastMessage } = props;
  const itemList =
    typeof msg.text === "string" ? JSON.parse(msg.text) : msg.text;

  return (
    <div className="list-container">
      <div className="list-container__items">
        {itemList.map((m, i) => {
          m.id = m.id ?? m.title;

          const image = m.image !== "None" ? m.image : "";
          const description = m.description !== "None" ? m.description : "";

          // const centered =
          //   description && description.trim() === "" ? "centered" : "";

          const centered = "";
          const hovered = lastMessage ? "hovered" : "";

          const noImageAndDescription = !(
            description &&
            description.trim() !== "" &&
            description === "None"
          )
            ? "no-image-no-description"
            : "";
          return (
            <div key={`list-item-${i}`}>
              {i !== 0 && <hr />}

              <div
                className={`list-container__items__each-item ${
                  itemSelected === m.id && !lastMessage ? "selected-item" : ""
                }
                 ${centered}
                 ${hovered}
                `}
                onClick={(_e) => {
                  if (!!lastMessage) {
                    setItemSelected(m.id);
                    onMessage(m.title);
                  }
                }}
              >
                <div className="list-container__items__each-item__info">
                  <strong className={noImageAndDescription}>{m.title}</strong>
                  {description && description.trim() !== "" && (
                    <span>{description}</span>
                  )}
                </div>
                {!!image && (
                  <div className="list-container__items__each-item__image">
                    <img src={image} alt={`list-item-${i}`} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
