import React, { useCallback, useMemo, useState } from "react";
import ListModalItem from "./ListModalItem";
import ListModalHeader from "./ListModalHeader";
import "./ListModal.scss";
import ListModalConfirm from "./ListModalConfirm";
import ListModalDetails from "./ListModalDetails";

export default function ListModal(props) {
  const { msg, suggestions, onMessage, closeModal, onChoice, isFetch } = props;
  const [itemsSelected, setItemSelected] = useState([]);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [modalItemDetails, setModalItemDetails] = useState({
    data: null,
    open: false,
  });

  const payload = useMemo(() => {
    return typeof msg?.text === "string" ? JSON.parse(msg?.text) : msg?.text;
  }, [msg]);

  const items = useMemo(() => {
    return payload.items ?? [];
  }, [payload]);

  const maxItensSelect = useMemo(() => {
    return items.length;
  }, [items]);

  const handleCloseModalDetails = useCallback(() => {
    setModalItemDetails({
      data: null,
      open: false,
    });
  }, []);

  const handleOpenModalDetails = useCallback((data) => {
    setModalItemDetails({
      data: data,
      open: true,
    });
  }, []);

  const handleCancelOperation = useCallback(() => {
    setItemSelected([]);
  }, []);

  const handleCompleteOperation = useCallback(() => {
    onChoice(itemsSelected);
    setItemSelected([]);
  }, [itemsSelected, onChoice]);

  const handleOpenConfirm = useCallback(() => {
    setOpenConfirmPopup(true);
  }, []);

  const handleSelectCarouselItem = useCallback(
    (item) => {
      const itemExistOnSelecteds = itemsSelected.find(
        (itemSelected) => itemSelected.id === item.id
      );

      if (!itemExistOnSelecteds && itemsSelected.length < maxItensSelect) {
        setItemSelected((old) => [...old, item]);
      } else if (itemExistOnSelecteds) {
        setItemSelected((old) =>
          old.filter((oldItem) => oldItem.id !== item.id)
        );
      }
    },
    [itemsSelected, maxItensSelect]
  );

  return (
    <div className="list-modal-container">
      <ListModalHeader
        onCancel={handleCancelOperation}
        onComplete={handleOpenConfirm}
        totalSelected={itemsSelected.length}
        isFetch={isFetch}
        closeModal={closeModal}
      />
      <div className="list-modal-container__items">
        {items.map((itemMessage, i) => {
          return (
            <ListModalItem
              key={`list-item-${i}`}
              item={itemMessage}
              itemsSelected={itemsSelected}
              hasDetails={!!itemMessage?.htmlDetails}
              handleOpenDetails={handleOpenModalDetails}
              handleSelectItem={handleSelectCarouselItem}
            />
          );
        })}
        {
          <div className="list-modal-container__suggestions">
            {suggestions.map((item) => (
              <button key={item} type="button" onClick={() => onMessage(item)}>
                {item}
              </button>
            ))}
          </div>
        }
      </div>

      {openConfirmPopup && (
        <ListModalConfirm
          totalItems={itemsSelected.length}
          onConfirm={handleCompleteOperation}
          onClose={() => setOpenConfirmPopup(false)}
          isFetch={isFetch}
        />
      )}

      {modalItemDetails.open && (
        <ListModalDetails
          itemDetails={modalItemDetails.data}
          closeDetails={handleCloseModalDetails}
        />
      )}
    </div>
  );
}
