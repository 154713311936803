import "./Footer.scss";
import React, { useCallback, useContext, useState } from "react";

import { MdAttachFile } from "react-icons/md";
import { MdSend } from "react-icons/md";

import { isMobile } from "react-device-detect";

import SendOptMenu from "../SendOptMenu";
import AudioButton from "./AudioButton";
import { LoadingCircle } from "src/components/Loading/Loading";
import useTimer from "src/hook/useTimer";
import { formatTime } from "src/utils/formatTime";
import { useAudioRecorderTools as useAudioTools } from "src/hook/audioRecorder/useAudioRecorderTools";
import { RequestContext } from "src/contexts/requestContext";
import getTheme from "src/themes/getTheme";
import { toast } from "react-toastify";

const Footer = ({
  onMessage: handleSendTextMessage,
  onFile,
  suggestions,
  onLocation,
}) => {
  const currentTheme = getTheme();
  const requestContext = useContext(RequestContext);
  const {
    isRecording,
    startRecording: startRecordingAudioRecorder,
    stopRecording: stopRecordingAudioRecorder,
    cancelRecording: cancelRecordingAudioRecorder,
    isRecordingCompatible,
  } = useAudioTools();

  const [message, setMessage] = useState("");
  const [showSendOptMenu, setShowSendOptMenu] = useState(false);
  const { startTimerCount, resetTimerCount, currentTimer } = useTimer(0);

  const submit = useCallback(
    (e, isAudio = false) => {
      if (!isAudio) e.preventDefault();

      const msg = message.trim();
      if (msg !== "") {
        handleSendTextMessage(msg);
        setMessage("");
      }
    },
    [message, handleSendTextMessage]
  );

  async function isMicrophoneAllowed() {
    const micStatus = await navigator.permissions.query({
      name: "microphone",
    });

    return micStatus.state !== "denied";
  }

  const startRecording = useCallback(async () => {
    try {
      const micAllowed = await isMicrophoneAllowed();
      if (micAllowed) {
        await startRecordingAudioRecorder();

        if (message.trim() === "" && !isRecording) startTimerCount();
      } else
        toast.warn(
          "Parece que o acesso ao microfone está bloqueado. Verifique suas permissões de áudio.",
          {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
          }
        );
    } catch (error) {
      console.log(error);
    }
  }, [isRecording, message, startRecordingAudioRecorder, startTimerCount]);

  const stopRecording = useCallback(async () => {
    try {
      resetTimerCount();
      await stopRecordingAudioRecorder();
    } catch (error) {
      console.log(error);
    }
  }, [resetTimerCount, stopRecordingAudioRecorder]);

  const cancelRecording = useCallback(async () => {
    try {
      resetTimerCount();
      await cancelRecordingAudioRecorder();
    } catch (error) {
      console.log(error);
    }
  }, [cancelRecordingAudioRecorder, resetTimerCount]);

  const handleAttachClick = useCallback(() => {
    setShowSendOptMenu(!showSendOptMenu);
  }, [showSendOptMenu]);

  return (
    <>
      {Boolean(suggestions.length) && (
        <div
          className={`suggestions-row ${
            suggestions.length < 3 ? "suggestions-fix" : ""
          }`}
        >
          {suggestions.map((suggestion, index) => {
            return (
              <button
                key={index}
                className="suggestion"
                onClick={() => handleSendTextMessage(`${suggestion}`)}
              >
                <div>
                  <span>{suggestion}</span>
                </div>
              </button>
            );
          })}
        </div>
      )}

      <div
        className={
          currentTheme?.name === "Namu" ? "footer-bar namu" : "footer-bar"
        }
      >
        {requestContext?.isFetch ? (
          <LoadingCircle />
        ) : (
          <form className="conversation-compose" onSubmit={submit}>
            <div className="conversation-container">
              <input
                className="conversation-container__input-text"
                name="inputs"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={
                  isRecording && (isMobile || window.ReactNativeWebView)
                    ? `${formatTime(currentTimer)}`
                    : "Digite uma mensagem..."
                }
                autoComplete="off"
                autoFocus
                disabled={isRecording}
              />

              {!isRecording && (
                <div className="conversation-container__input-file">
                  <button type="button" onClick={handleAttachClick}>
                    <MdAttachFile />
                  </button>
                </div>
              )}
            </div>
            {isRecordingCompatible && message.trim() === "" ? (
              <AudioButton
                isRecording={isRecording}
                startRecording={startRecording}
                stopRecording={stopRecording}
                cancelRecording={cancelRecording}
                timer={formatTime(currentTimer)}
                onMessage={handleSendTextMessage}
              />
            ) : (
              <button className="send">
                <div className="circle">
                  <MdSend />
                </div>
              </button>
            )}
          </form>
        )}
      </div>

      {showSendOptMenu && (
        <SendOptMenu
          onLocation={onLocation}
          sendFile={onFile}
          handleAttachClick={handleAttachClick}
          isRecordingCompatible={isRecordingCompatible}
        />
      )}
    </>
  );
};

export default Footer;
