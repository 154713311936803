import React from "react";
import "./NotAuthorized.scss";
import Illustration from "./illustration";

const NotAuthorized = ({ getMessages }) => {
  return (
    <div className="notauthorized-container">
      <Illustration
        alt="Acesso não autorizado"
        style={{ width: "75vw", height: "75vw" }}
      />
      <h3 className="notauthorized-title">Acesso não autorizado!</h3>
      <p className="notauthorized-content">
        Parece que você não tem autorização para acessar essa tela. Verifique as
        suas permissões e tente novamente.
      </p>
      <button
        className="notauthorized-button"
        onClick={() => getMessages(true)}
      >
        Tentar novamente
      </button>
    </div>
  );
};

export default NotAuthorized;
