import React from "react";
import getTheme from "../../themes/getTheme";

const Illustration = () => {
  const currentTheme = getTheme();
  const primary = currentTheme.dark?.color;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="238.319" height="205.541" viewBox="0 0 238.319 205.541">
      <defs>
        <clipPath id="a">
          <rect width="238.319" height="205.541" fill="none" />
        </clipPath>
        <clipPath id="b">
          <path
            d="M447.186,240.841c-1.993-4.764-1.5-19.3.648-25.2a2.233,2.233,0,0,1,3.588-.9s5.98,4.066,8.97,15.2c0,0,14.95,26.322,14.95,38.332,0,11.262-22.256,34.694-22.256,34.694a10.405,10.405,0,0,1-5.98-4.754s8.342-20.551,14.84-29.4C461.946,268.818,449.4,246.1,447.186,240.841Z"
            transform="translate(-445.929 -214.178)"
            fill="#757575"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="M381.929,250.687c2.472,6.389,6.588,6.977,9.877,6.149a11.88,11.88,0,0,0,6.618-4.635l1.266-1.744c.07,22.923-1.266,51.438-1.266,51.438s34.276,6.05,51.488-.748c0,0-.668-19.216,4.983-50.083s3.13-43.923-34.883-39.438c-3.638.429-7.973,0-11.7,1.515a7.973,7.973,0,0,0-3.229,1C404.644,214.488,379.368,244.1,381.929,250.687Z"
            transform="translate(-381.747 -210.768)"
            fill="#757575"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <g transform="translate(-45.894 -66.61)">
          <g transform="translate(45.894 70.071)">
            <path
              d="M116.086,266.938s37.933,16.727,77.357-2.648,61.321-61.942,79.969-95.957,11.33-70.529-16.727-88.974-49.3,4.174-90.143,2.385-51.446-8.468-86.7,12.3S35.128,169.234,59.339,211.782,116.086,266.938,116.086,266.938Z"
              transform="translate(-45.894 -72.076)"
              fill="#fff"
            />
            <path
              d="M116.086,266.938s37.933,16.727,77.357-2.648,61.321-61.942,79.969-95.957,11.33-70.529-16.727-88.974-49.3,4.174-90.143,2.385-51.446-8.468-86.7,12.3S35.128,169.234,59.339,211.782,116.086,266.938,116.086,266.938Z"
              transform="translate(-45.894 -72.076)"
              fill="#fff"
              opacity="0"
            />
          </g>
          <g transform="translate(46.637 67.11)">
            <path
              d="M86.36,132.24A78.4,78.4,0,0,0,70.2,172.707L59,171.073A89.582,89.582,0,0,1,77.665,125Z"
              transform="translate(-51.927 -90.478)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M204.527,340.628A90.107,90.107,0,0,1,109,325.368l7.508-8.468a78.824,78.824,0,0,0,83.523,13.334Z"
              transform="translate(-72.111 -167.941)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M182.587,229.4a78.824,78.824,0,0,1-83.523-13.334l-7.5,8.468a90.083,90.083,0,0,1-25.762-39l10.883-3.178,4.586-1.336,8.027-2.344,54.189-15.827h0l3.7-1.079-39.418,44.469a65.263,65.263,0,0,0,17,10.931l24.772-56.086,1.527-.447-.9-.972-.6-.6-.37-.4-1.079-1.157-.316-.34-2.385-2.582-40.623-43.718-5.683-6.118-3.25-3.5-7.7-8.319A89.761,89.761,0,0,1,151.279,67.11v90.047L144.23,91.936A65.15,65.15,0,0,0,112.565,104.2l38.714,52.955,7.073,65.222a65,65,0,0,0,19.041-5.021l3.316,7.663Z"
              transform="translate(-54.672 -67.11)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M97.59,140.213a73.516,73.516,0,0,0-15.1,38.076l-4.711-.674A78.4,78.4,0,0,1,93.941,137.16Z"
              transform="translate(-59.508 -95.386)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M160.646,222.726l1.33-1.5.9-1.014.179-.2,1.682-1.9H99.14"
              transform="translate(-68.13 -128.063)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M69.8,218.11H47.14"
              transform="translate(-47.14 -128.063)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="10.048"
              y2="11.336"
              transform="translate(26.835 157.427)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M121.58,310.248l3.167-3.572,5.54-6.256"
              transform="translate(-77.189 -161.288)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M307.761,176.412a78.717,78.717,0,0,1-47.409,72.246l-1.9-4.383a73.946,73.946,0,0,0,16.632-125.726l2.982-3.739A78.592,78.592,0,0,1,307.761,176.412Z"
              transform="translate(-132.438 -86.365)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M273.132,114.468l-5.2,6.56a65.15,65.15,0,0,0-14.312-8.683L257,104.7a73.874,73.874,0,0,1,16.137,9.768Z"
              transform="translate(-130.488 -82.284)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M291.33,101.207l15.988-20.1h17.89"
              transform="translate(-145.71 -72.761)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="40.837"
              y2="51.333"
              transform="translate(96.607 38.714)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M177.209,120.995l-1.324-12.255a65.15,65.15,0,0,0-31.666,12.267l7.275,9.953A52.99,52.99,0,0,1,177.209,120.995Z"
              transform="translate(-86.327 -83.914)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M219.09,307.231l1.139,10.311a65,65,0,0,0,19.041-5.021L235.1,302.86A53.336,53.336,0,0,1,219.09,307.231Z"
              transform="translate(-116.55 -162.273)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M171.842,247.474a65.848,65.848,0,0,1-33.556-5.206l24.772-56.086-2.356.686-39.418,44.469A65.484,65.484,0,0,1,99.22,182.253q0-3.005.274-5.963A65.608,65.608,0,0,1,126.1,129.28l38.714,52.955Z"
              transform="translate(-68.163 -92.206)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="1.723"
              y2="3.9"
              transform="translate(126.489 18.487)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="26.507"
              y2="60.015"
              transform="translate(96.607 30.032)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M195.04,87.69l.459,4.252.9,8.3"
              transform="translate(-106.842 -75.417)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="71.561"
              y1="31.606"
              transform="translate(25.046 58.441)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M93.864,225.159l-8.3.9a74.505,74.505,0,0,1-.423-7.949h8.349a65.529,65.529,0,0,0,.376,7.049Z"
              transform="translate(-62.479 -128.063)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="4.252"
              y2="0.459"
              transform="translate(18.832 97.996)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="63.152"
              y2="46.169"
              transform="translate(33.455 90.047)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M156.14,330.3l1.723-3.9,3.375-7.645"
              transform="translate(-91.139 -168.687)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M206.23,222.04l.274-.626.28-.632.072-.161,1.109-2.511"
              transform="translate(-111.359 -128.063)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M222.928,350.588l-1.067-14.807-.9-8.3"
              transform="translate(-117.304 -172.211)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M281.514,317.107l-2.529-3.453-4.926-6.745"
              transform="translate(-138.739 -163.908)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="71.561"
              y2="31.606"
              transform="translate(96.607 90.047)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              y1="8.408"
              x2="77.774"
              transform="translate(96.607 81.639)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="3.453"
              y2="2.528"
              transform="translate(156.306 43.879)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="52.955"
              y2="38.714"
              transform="translate(96.607 51.333)"
              fill="none"
              stroke={primary}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M213.086,194.6h-33.1a2.719,2.719,0,0,1-2.749-2.689v-15.26a19.314,19.314,0,0,1,38.619,0V191.89a2.725,2.725,0,0,1-2.773,2.707Zm-30.348-5.367h27.6V176.648a13.805,13.805,0,0,0-27.6,0Z"
              transform="translate(-99.656 -103.698)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              width="42.137"
              height="34.856"
              transform="translate(75.538 83.547)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M210.278,228.484a4.592,4.592,0,0,0-9.178,0,4.479,4.479,0,0,0,2.385,3.954l-.769,7.919h5.9l-.769-7.919A4.479,4.479,0,0,0,210.278,228.484Z"
              transform="translate(-109.288 -130.464)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g transform="translate(229.857 77.62)">
            <path
              d="M454.529,266.259l4.148-19.019H448.65Z"
              transform="translate(-425.479 -229.426)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              width="24.38"
              height="26.628"
              transform="translate(5.332 1.595)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              width="24.38"
              height="26.628"
              transform="translate(3.458)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              width="6.224"
              height="6.783"
              transform="translate(5.188 3.075)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="6.46"
              transform="translate(18.784 3.289)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="3.619"
              transform="translate(13.658 3.289)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="2.363"
              transform="translate(22.881 5.706)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="7.929"
              transform="translate(13.658 5.706)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="6.673"
              transform="translate(18.57 8.128)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="2.973"
              transform="translate(13.658 8.128)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="4.949"
              transform="translate(20.294 10.549)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="4.266"
              transform="translate(13.658 10.549)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="2.363"
              transform="translate(22.881 12.971)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="4.093"
              transform="translate(16.414 12.971)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x2="1.705"
              transform="translate(13.658 12.971)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <circle
              cx="1.576"
              cy="1.576"
              r="1.576"
              transform="translate(6.765 4.027)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M405.112,220.3a1.6,1.6,0,0,0-1.389-.808h-1.617a1.606,1.606,0,0,0-1.3.676l-1.1,1.565h6.224Z"
              transform="translate(-394.515 -211.873)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M385.59,235.643l3.976,23.171H414.64l-2.421-21.095h-14.18l-1.9-2.249Z"
              transform="translate(-385.59 -221.981)"
              fill={primary}
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M422.082,258.722a2.289,2.289,0,0,0-2.241-2.072,1.885,1.885,0,0,0-1.907,2.072,2.274,2.274,0,0,0,1.121,1.767l-.107,5.67h3.112l-1-5.578A1.885,1.885,0,0,0,422.082,258.722Z"
              transform="translate(-406.043 -235.378)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <g transform="translate(113.411 251.19)">
            <path
              d="M231.133,423.19H179.427a5.1,5.1,0,0,1-5.1-5.093h0a5.1,5.1,0,0,1,5.1-5.1h51.706a5.1,5.1,0,0,1,5.093,5.1h0A5.1,5.1,0,0,1,231.133,423.19Z"
              transform="translate(-174.33 -413)"
              fill="#263238"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M187.388,420.078l-.477-3.028h1.652l-.477,3.028,2.737-1.376.515,1.562-3.013.5,2.148,2.148-1.32.973-1.413-2.714-1.413,2.729L185,422.923l2.148-2.148-3.009-.511.515-1.562Z"
              transform="translate(-180.482 -415.54)"
              fill="#fff"
            />
            <path
              d="M219.255,420.078l-.477-3.028h1.652l-.477,3.028,2.737-1.376.515,1.562-3.013.5,2.148,2.163-1.32.973-1.413-2.714-1.413,2.714-1.324-.973,2.148-2.148L216,420.264l.511-1.562Z"
              transform="translate(-200.463 -415.54)"
              fill="#fff"
            />
            <path
              d="M251.16,420.078l-.477-3.028h1.652l-.477,3.028,2.737-1.376.515,1.562-3.013.5,2.148,2.148-1.32.973-1.413-2.714-1.417,2.714-1.32-.973,2.159-2.148-3.013-.511.515-1.562Z"
              transform="translate(-220.481 -415.54)"
              fill="#fff"
            />
            <path
              d="M283.021,420.078l-.477-3.028H284.2l-.477,3.028,2.737-1.376.515,1.562-3.013.5,2.148,2.148-1.32.973-1.417-2.714-1.413,2.714-1.32-.973,2.148-2.148-3.013-.511.515-1.562Z"
              transform="translate(-240.455 -415.54)"
              fill="#fff"
            />
            <path
              d="M314.92,420.078l-.477-3.028h1.652l-.477,3.028,2.737-1.376.511,1.562-3.009.5L318,422.908l-1.324.973-1.413-2.714-1.413,2.714-1.309-.958,2.148-2.148-3.013-.511.515-1.562Z"
              transform="translate(-260.466 -415.54)"
              fill="#fff"
            />
          </g>
          <g transform="translate(190.332 132.412)">
            <path
              d="M447.186,240.841c-1.993-4.764-1.5-19.3.648-25.2a2.233,2.233,0,0,1,3.588-.9s5.98,4.066,8.97,15.2c0,0,14.95,26.322,14.95,38.332,0,11.262-22.256,34.694-22.256,34.694a10.405,10.405,0,0,1-5.98-4.754s8.342-20.551,14.84-29.4C461.946,268.818,449.4,246.1,447.186,240.841Z"
              transform="translate(-381.961 -165.158)"
              fill="#cbcbcb"
            />
            <g transform="translate(63.968 49.02)" clipPath="url(#b)">
              <path
                d="M459.474,238.52c-1.854-.927-5.771,5.85-8.063,10.246,3.239,4.694,6.538,9.628,7.654,15.149a5.1,5.1,0,0,1,1.326,3.12,5.442,5.442,0,0,1-1.066,5.173c-2.99,7.335-6.179,14.651-10.575,21.269a27.452,27.452,0,0,1,1.854,3.349c5.721-8.3,13.9-24.319,15.827-27.289C469.092,265.46,462.2,239.875,459.474,238.52Z"
                transform="translate(-445.939 -214.258)"
                opacity="0.2"
              />
            </g>
            <path
              d="M447.186,240.841c-1.993-4.764-1.5-19.3.648-25.2a2.233,2.233,0,0,1,3.588-.9s5.98,4.066,8.97,15.2c0,0,14.95,26.322,14.95,38.332,0,11.262-22.256,34.694-22.256,34.694a10.405,10.405,0,0,1-5.98-4.754s8.342-20.551,14.84-29.4C461.946,268.818,449.4,246.1,447.186,240.841Z"
              transform="translate(-381.961 -165.158)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M456.8,300.408l-2.621,3.628s-5.611-1.306-8.422-6.04l2.551-4.036S450.245,298.485,456.8,300.408Z"
              transform="translate(-381.96 -165.423)"
              fill={primary}
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M405.166,212.828c1.076,1.864,3.987,1.216,6.1,1.176a7.555,7.555,0,0,0,5.551-2.641,4.986,4.986,0,0,0,.738-1.146,7.216,7.216,0,0,1-2.671.429,1.853,1.853,0,0,1-.817-.179.708.708,0,0,1-.4-.678c.05-.419.528-.6.917-.708a31.276,31.276,0,0,1,3.139-.678,1.078,1.078,0,0,1,.369.09,4.1,4.1,0,0,0,.08-.6,7.265,7.265,0,0,1-2.88.508,1.784,1.784,0,0,1-.827-.169.7.7,0,0,1-.389-.688c.05-.409.518-.588.917-.7a31.28,31.28,0,0,1,3.139-.678h.06v-.309a2.2,2.2,0,0,1-2.173-2.213v-1.555c-1.585.5-4.784,1.266-5.372,2.133.638-2.462.7-4.844.249-5.631s-1.136-1.385-1.924-1.216c-1,.239-1.176,1.654-1.067,2.721a7.973,7.973,0,0,1-.189,2.462c-.3,1.585-2.99,3.628-3.608,5.1C403.332,209.36,405.166,212.828,405.166,212.828Z"
              transform="translate(-381.821 -165.102)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M412.334,210.1a5.721,5.721,0,0,0-1.385-4.834,7.6,7.6,0,0,1-3.4,5.98"
              transform="translate(-381.833 -165.128)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <line
              x1="2.043"
              y2="0.608"
              transform="translate(32.245 39.295)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M414.11,207.575a.7.7,0,0,0,.389.688,1.784,1.784,0,0,0,.827.169,7.266,7.266,0,0,0,2.88-.508l.219-.07c.4-.159.359-1,.4-1.455-.12-.05-.369-.508-.6-.2h-.06c-1.057.179-2.1.4-3.14.678C414.628,207,414.16,207.166,414.11,207.575Z"
              transform="translate(-381.855 -165.131)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M413.7,209.825a.707.707,0,0,0,.4.678,1.854,1.854,0,0,0,.817.179,7.217,7.217,0,0,0,2.671-.429,3.8,3.8,0,0,0,.439-.15c.4-.219.488-.538.389-1.455a2.617,2.617,0,0,0-.289-.11h0a1.075,1.075,0,0,0-.369-.09c-1.057.179-2.1.4-3.14.678C414.228,209.227,413.75,209.407,413.7,209.825Z"
              transform="translate(-381.853 -165.139)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M414,195.4l1.993.568v7.585a2.2,2.2,0,0,0,2.173,2.213h4.385l.369,5.551,14.342-1.455-1.475-8.731s.578-.508,1.475-1.4l-1.475-1.734,4.983-12.03L430.641,172.57h-6.678l-2.432-2.99a2.282,2.282,0,0,0-.747.877s-4.176,8.223-4.784,11.2c-.269,1.286.249,3.937,0,5.223a48.754,48.754,0,0,1-2.87,6.737A1.326,1.326,0,0,0,414,195.4Z"
              transform="translate(-381.851 -165.009)"
              fill="#fff"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M421.6,196.52a4.983,4.983,0,0,1-4.3,1.615"
              transform="translate(-381.865 -165.099)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <ellipse cx="0.578" cy="1.236" rx="0.578" ry="1.236" transform="translate(35.514 20.119)" fill="#263238" />
            <path
              d="M416.49,182.8a3.438,3.438,0,0,1,3.917,2.083"
              transform="translate(-381.863 -165.053)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M442.766,212.821s-.05-6.7-11.551-5.661c-14.043,1.276-19.7,8.462-19.7,8.462Z"
              transform="translate(-381.846 -165.134)"
              fill={primary}
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M408.322,214.176c0,.08-11.442,33.518-11.442,33.518s-4.485,10.894-13.714,4.754c-7.186-4.784,19.026-45.4,19.026-45.4S409.259,209.871,408.322,214.176Z"
              transform="translate(-381.748 -165.134)"
              fill="#cbcbcb"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M381.929,250.687c2.472,6.389,6.588,6.977,9.877,6.149a11.88,11.88,0,0,0,6.618-4.635l1.266-1.744c.07,22.923-1.266,51.438-1.266,51.438s34.276,6.05,51.488-.748c0,0-.668-19.216,4.983-50.083s3.13-43.923-34.883-39.438c-3.638.429-7.973,0-11.7,1.515a7.973,7.973,0,0,0-3.229,1C404.644,214.488,379.368,244.1,381.929,250.687Z"
              transform="translate(-381.747 -165.146)"
              fill="#cbcbcb"
            />
            <g transform="translate(0 45.621)" clipPath="url(#c)">
              <path
                d="M401.547,253.925c.488,1.3,1.405,2.631,2.781,2.771,1.555.15,2.761-1.276,3.618-2.581a56.6,56.6,0,0,0,8-19.634c.648-3.289-.708-3.578-2.7-2.571l-.159.229-12.3,16.943h0a11.959,11.959,0,0,0,.758,4.844Z"
                transform="translate(-381.81 -210.836)"
                opacity="0.2"
              />
              <path
                d="M412.3,286.541a15.587,15.587,0,0,0,5.88,1.824,145.443,145.443,0,0,0,14.85,1.625,7.974,7.974,0,0,1,4.146,1c1.176.817,1.784,2.651.817,3.718a9.708,9.708,0,0,0,4.316-1.814c1.176-1.047,1.764-2.99.847-4.236s-2.99-1.346-4.7-1.216a95.023,95.023,0,0,1-20.741-.718,13.955,13.955,0,0,1-4.794-1.405c-1.066-.578-1.515-1.585-2.811-1.306A4.8,4.8,0,0,0,412.3,286.541Z"
                transform="translate(-381.841 -211.011)"
                opacity="0.2"
              />
            </g>
            <path
              d="M381.929,250.687c2.472,6.389,6.588,6.977,9.877,6.149a11.88,11.88,0,0,0,6.618-4.635l1.266-1.744c.07,22.923-1.266,51.438-1.266,51.438s34.276,6.05,51.488-.748c0,0-.668-19.216,4.983-50.083s3.13-43.923-34.883-39.438c-3.638.429-7.973,0-11.7,1.515a7.973,7.973,0,0,0-3.229,1C404.644,214.488,379.368,244.1,381.929,250.687Z"
              transform="translate(-381.747 -165.146)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M470.8,268c.5.12,1,.259,1.555.439"
              transform="translate(-382.043 -165.337)"
              fill="#999"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M463.56,268.467s1.844-1.316,5.85-.708"
              transform="translate(-382.019 -165.336)"
              fill="#999"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              x1="3.269"
              y2="12.658"
              transform="translate(74.235 66.853)"
              fill="#999"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              y2="8.801"
              transform="translate(67.996 125.078)"
              fill="#999"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M411.13,292.67c4.146,1.993,10.445,3.987,27.568,2.681"
              transform="translate(-381.845 -165.419)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M406.34,288.62a5.363,5.363,0,0,0,1.874,2.292"
              transform="translate(-381.829 -165.406)"
              fill="#999"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M408.471,213.13s-1.495-6.538-6.219-6.08L399.92,211.3a3.8,3.8,0,0,1,3.987,2.88Z"
              transform="translate(-381.808 -165.134)"
              fill={primary}
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M424.1,202.07c.2,2.332-1.844,4.794-4.166,4.654-3.468-.209-4.8-2.322-2.99-3.4C416.917,203.326,419.579,204.711,424.1,202.07Z"
              transform="translate(-381.862 -165.118)"
              fill="#192e34"
            />
            <path
              d="M421.775,175.644a14.618,14.618,0,0,0,1.993,2.173c-.2,1.226,2.671,9.139,2.542,11.1.887-1.993,2.123-5.173,3.987-5.2a3.987,3.987,0,0,1,2.133,2.3c1.146,2.86-5.153,8.312-5.153,8.312l-.07,5.821c.07,1.794,6.12,1.365,8.631,1,1.784-.289,11.292-9.179,12.767-12.249a15.408,15.408,0,0,0,1.475-9.877s0-.07,0-.1a10.135,10.135,0,0,1-2.262-.3,7.126,7.126,0,0,1-4.405-2.94,4.475,4.475,0,0,1,.6-5.492A24.269,24.269,0,0,0,429.848,165C423.808,164.811,417.12,169.216,421.775,175.644Z"
              transform="translate(-381.875 -164.994)"
              fill="#192e34"
            />
            <path
              d="M437.81,171.4a4.256,4.256,0,0,1,2.751.468"
              transform="translate(-381.934 -165.015)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M438.17,173.789a8.91,8.91,0,0,1,3.628-.3"
              transform="translate(-381.935 -165.022)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M439,176.236,442.987,175"
              transform="translate(-381.938 -165.027)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M443.828,176.49a6.868,6.868,0,0,1-3.628,2.322"
              transform="translate(-381.942 -165.032)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M444,178a1.665,1.665,0,0,1-.887,1.505"
              transform="translate(-381.951 -165.037)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M445.106,179.38A2.79,2.79,0,0,1,444,180.995"
              transform="translate(-381.954 -165.042)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M446.712,180.1a5.98,5.98,0,0,1-2.372,3.877v-.249"
              transform="translate(-381.955 -165.044)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M448,181.52a4.9,4.9,0,0,1-.728,1.944"
              transform="translate(-381.965 -165.049)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M449.119,182.85l-.179.359"
              transform="translate(-381.971 -165.054)"
              fill="none"
              stroke="silver"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              d="M443.438,175.672a7.126,7.126,0,0,0,4.405,2.94,10.137,10.137,0,0,0,2.262.3,15.946,15.946,0,0,0-5.671-8.412l-.4-.319A4.475,4.475,0,0,0,443.438,175.672Z"
              transform="translate(-381.95 -165.011)"
              fill="#192e34"
            />
            <path
              d="M443.438,175.662a7.126,7.126,0,0,0,4.405,2.94,10.136,10.136,0,0,0,2.262.3,7.565,7.565,0,0,0,3.737-.9,5.6,5.6,0,0,0,2.94-5.093,6.09,6.09,0,0,0-3.349-4.664,7.306,7.306,0,0,0-5.043-.767,8.322,8.322,0,0,0-4.355,2.691,4.475,4.475,0,0,0-.6,5.492Z"
              transform="translate(-381.95 -165.002)"
              fill="#192e34"
            />
            <path
              d="M419.36,229.65c-3.349,4.824-12.08,10.535-12.08,10.535"
              transform="translate(-381.832 -165.209)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M443.459,291.975c1.106-.608.917-2.392,0-3.219a5.921,5.921,0,0,0-3.558-1.047l-21.08-1.585A15.011,15.011,0,0,1,413.3,285a5.133,5.133,0,0,1-3.13-4.365,7.564,7.564,0,0,1,1.824-4.2Q419.22,266.6,427.3,257.41"
              transform="translate(-381.842 -165.302)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M406,213.719c8.073-1.166,8.512,4.754,8.322,8.492"
              transform="translate(-381.828 -165.156)"
              fill="none"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M400.829,249.044l-.319.458.319-.419c2.821-3.867,8.342-11.422,12.3-16.943Z"
              transform="translate(-381.81 -165.218)"
              fill="#bfbfbf"
              stroke="#263238"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Illustration;
