export type MessageContentType =
  | "text"
  | "audio"
  | "list"
  | "location"
  | "file"
  | "carousel"
  | "carousel_choice"
  | "invisible"
  | "image_location"
  | "payload";

export interface Message {
  contentType: MessageContentType;
  text: string | object;
}

export interface PayloadProps {
  msg: Message;
  sendMessage: (text: string, type: MessageContentType) => any;
  isLastMessage?: boolean;
}

export const PayloadReceivedTypeConst = {
  CARDS: "cards",
  PUSH_NOTIFICATION: "push_notification",
  IFRAME: "iframe",
};

export type PayloadReceivedType = "cards" | "notification";

export interface PayloadReceivedProps {
  type: PayloadReceivedType;
  items: any;
  onClose?: string;
  userId?: string;
  setShow: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}
