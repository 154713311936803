import { useEffect } from "react";
import { CONTENTTYPE } from "src/contexts/store/appReducer";

export default function SalesForceChat(props) {
  const { message, sendMessage, isLastMessage } = props;
  const msgObject = message.text ? JSON.parse(message.text) : {};
  const {
    cpf,
    nome,
    sobrenome,
    email,
    id,
    listaRegistro,
    ultimoRegistro,
    tempo,
    messagesHistory,
  } = msgObject;

  const wasChatOpenedBefore = sessionStorage.getItem(
    "@falazap:wasChatOpenBefore"
  );

  useEffect(() => {
    if (
      wasChatOpenedBefore &&
      window.window.embedded_svc &&
      window.embedded_svc.utils
    ) {
      window.embedded_svc.bootstrapEmbeddedService();
    } else if (!window.window.embedded_svc) {
      var s = document.createElement("script");
      s.setAttribute(
        "src",
        "https://avenuesecurities--devfintalk.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js"
      );
      s.onload = function () {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW("https://service.force.com");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function initESW(gslbBaseURL) {

    window.embedded_svc.settings.displayHelpButton = true; //Or false
    window.embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'

    window.embedded_svc.settings.defaultMinimizedText = "Chat Avenue"; // (Defaults to Chat with an Expert)

    window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    window.embedded_svc.settings.entryFeature = "LiveAgent";

    window.embedded_svc.addEventHandler("afterDestroy", function () {
      console.log("afterDestroy event was fired. janela fechou");
      sendMessage("#endChat", CONTENTTYPE.TEXT);
    });

    window.embedded_svc.addEventHandler("onSettingsCallCompleted", function () {
      if (isLastMessage) {
        window.embedded_svc.bootstrapEmbeddedService();
        sessionStorage.setItem("@falazap:wasChatOpenBefore", "true");
        console.log("finalizou carregamento de recursos, abrindo chat");
      }
    });

    window.embedded_svc.settings.extraPrechatInfo = [
      {
        entityName: "Contact",
        showOnCreate: false,
        linkToEntityName: "Case",
        linkToEntityField: "ContactId",
        saveToTranscript: "ContactId",
        entityFieldMaps: [
          {
            isExactMatch: true,
            fieldName: "FirstName",
            doCreate: false,
            doFind: true,
            label: "First Name",
          },
          {
            isExactMatch: true,
            fieldName: "LastName",

            doCreate: false,
            doFind: true,
            label: "Last Name",
          },
          {
            isExactMatch: true,
            fieldName: "Email",
            doCreate: false,
            doFind: true,
            label: "Email",
          },
        ],
      },
      {
        entityName: "Case",
        showOnCreate: true,
        saveToTranscript: "CaseId",
        entityFieldMaps: [
          {
            isExactMatch: false,
            fieldName: "Status",
            doCreate: true,
            doFind: false,
            label: "Status",
          },
          {
            isExactMatch: false,
            fieldName: "Origin",
            doCreate: true,
            doFind: false,
            label: "Origin",
          },
        ],
      },
      {
        entityName: "Account",
        showOnCreate: true,
        saveToTranscript: "AccountId",
        entityFieldMaps: [
          {
            isExactMatch: false,
            fieldName: "PersonEmail",
            doCreate: false,
            doFind: true,
            label: "Email",
          },
        ],
      },
    ];

    window.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: "First Name",
        name: "FirstName",
        value: nome,
        displayToAgent: false,
      },
      {
        label: "CPF",
        value: cpf,
        transcriptFields: ["CPF__c"],
        displayToAgent: true,
      },
      {
        label: "Primeiro Nome",
        value: nome,
        transcriptFields: ["Primeiro_Nome__c"],
        displayToAgent: true,
      },
      {
        label: "Sobrenome",
        value: sobrenome,
        transcriptFields: ["Sobrenome__c"],
        displayToAgent: true,
      },
      {
        label: "Email",
        value: email,
        transcriptFields: ["Email__c"],
        displayToAgent: true,
      },
      {
        label: "Custom Body",
        value: messagesHistory,
        transcriptFields: ["CustomBody__c"],
        displayToAgent: true,
      },
      {
        label: "Artigos Acessados Hierárquico",
        value: listaRegistro,
        transcriptFields: ["Artigos_Acessados_Hier_rquico__c"],
        displayToAgent: true,
      },
      {
        label: "Último Registro",
        value: ultimoRegistro,
        transcriptFields: ["ltimo_Registro__c"],
        displayToAgent: true,
      },
      {
        label: "Tempo Permanência Chatbot",
        value: tempo,
        transcriptFields: ["Tempo_Perman_ncia_Chatbot__c"],
        displayToAgent: true,
      },
      {
        label: "Transcrição",
        value: id,
        displayToAgent: true,
        transcriptFields: ["Transcri_o__c"],
      },

      {
        label: "Origin",
        value: "Chat Atendimento" /*Informação fixa para identificar o
        caso no salesforce, sempe passar "Chat Atendimento"*/,
        displayToAgent: false,
      },
      {
        label: "Status",
        value: "New" /*Informação fixa para identificar o caso no sale
        sforce, sempre passar "New"*/,
        displayToAgent: false,
      },
    ];

    window.embedded_svc.init(
      "https://avenuesecurities--devfintalk.sandbox.my.salesforce.com",
      "https://avenuesecurities--devfintalk.sandbox.my.salesforce-sites.com/atendimento",
      gslbBaseURL,
      "00D22000000DcqZ",
      "Atendimento_Nao_Logado",
      {
        baseLiveAgentContentURL:
          "https://c.la1-c1cs-ia2.salesforceliveagent.com/content",
        deploymentId: "5722h0000000152",
        buttonId: "5732h00000001Lh",
        baseLiveAgentURL: "https://d.la1-c1cs-ia2.salesforceliveagent.com/chat",
        eswLiveAgentDevName:
          "EmbeddedServiceLiveAgent_Parent04I2h0000008ObqEAE_17e49a6cb52",
        isOfflineSupportEnabled: false,
      }
    );

    if (isLastMessage) {
      setTimeout(() => {
        window.embedded_svc.bootstrapEmbeddedService();
      }, 5000);
    }
  }

  return null;
}
