import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

import "./ListModalHeader.scss";
import { ListModalLoading } from "../ListModalLoading";

const ListModalHeader = (props) => {
  const { onCancel, onComplete, totalSelected, isFetch, closeModal } = props;
  return (
    <>
      <div className="list-modal-header-bar">
        <div className="list-modal-header-left">
          <button
            className="list-modal-header-back"
            type="button"
            onClick={totalSelected === 0 ? closeModal : onCancel}
            disabled={isFetch}
          >
            {isFetch ? <ListModalLoading /> : <BsArrowLeft size={30} />}
          </button>
          <div className="list-modal-header-name">
            {totalSelected > 0 ? (
              <span>{totalSelected}</span>
            ) : (
              <span>Catálogo</span>
            )}
          </div>
        </div>

        {totalSelected > 0 && (
          <div className="list-modal-header-right">
            <button
              className="list-modal-header-cancel"
              type="button"
              onClick={onCancel}
            >
              <AiOutlineCloseCircle size={25} />
            </button>
            <button
              className="list-modal-header-confirm"
              type="button"
              onClick={onComplete}
            >
              <AiOutlineCheckCircle size={25} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ListModalHeader;
